<template>
  <div>
    <div class="header-reference-slider">Haz click en cualquier equipo</div>
    <div class="carousel-categories">
      <BCarousel
        :autoplay="false"
        :indicator-inside="false"
        :pause-info="false"
        :progress="false"
        @change="changeItemCategory"
        v-model="categorySelected"
      >
        <BCarouselItem v-for="(carousel, i) in groups" :key="i">
          <section class="categories-container-mini">
            {{ carousel.name }}
          </section>
        </BCarouselItem>
      </BCarousel>
    </div>
    <div class="carousel-groups">
      <BCarousel
        :key="updateSlider"
        :autoplay="false"
        :pause-info="false"
        :arrow="false"
        :indicator-inside="false"
        @change="changeItem"
        v-model="categorySelected"
      >
        <template v-for="(dataGroup, index) in data">
          <template v-for="(group, indexGe) in dataGroup">
            <BCarouselItem :key="indexGe + index">
              <div class="cont-table-mundial-slider">
                <TableMundialGroup :data="group" :header="false" :teamselect="teamselect" :season="season" />
              </div>
            </BCarouselItem>
          </template>
        </template>
      </BCarousel>
    </div>
  </div>
</template>
<script>
import { BCarousel, BCarouselItem } from 'buefy/dist/esm/carousel';
export default {
  name: 'TableMundialSlider',
  components: {
    BCarousel,
    BCarouselItem,
    TableMundialGroup: () => import('@/components/Sections/Table/TableMundialGroup'),
  },
  props: {
    select: {
      type: Number,
      default: 0,
    },
    data: {
      type: Array,
      default: () => [],
    },
    season: {
      type: [Number, String],
      default: 0,
    },
    teamselect: { type: Number, default: 0 },
  },
  watch: {
    select: {
      immediate: true,
      handler: function(newValue) {
        this.categorySelected = newValue;
      },
    },
    data: {
      immediate: true,
      handler: function(newValue) {
        if (newValue != null) {
          for (let index = 0; index < newValue.length; index++) {
            for (let indexG = 0; indexG < Object.keys(newValue[index]).length; indexG++) {
              this.groups.push({ group: newValue[index][indexG].group_id, name: newValue[index][indexG].name });
            }
          }
        }
      },
    },
  },
  data() {
    return {
      updateSlider: 0,
      categorySelected: 0,
      groups: [],
    };
  },
  async created() {},
  mounted() {},
  destroyed() {},
  computed: {},
  methods: {
    changeItemCategory(index) {
      this.categorySelected = index;
    },
    changeItem(index) {
      this.categorySelected = index;
    },
  },
};
</script>
<style lang="scss">
.cont-table-mundial-slider {
  width: 96%;
  height: auto;
  display: inline-block;
  margin: 0 auto;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom: solid 1px #e9ebf0;
  border-right: solid 1px #e9ebf0;
  border-left: solid 1px #e9ebf0;
  overflow: hidden;
}
.header-reference-slider {
  width: 100%;
  height: 20px;
  margin-top: 11px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: -0.25px;
  text-align: center;
  color: #132839;
  font-family: 'Circular-Std-Medium';
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.item-category-modal {
  width: 97%;
  height: 26px;
  line-height: 26px;
  font-family: 'Avenir-Demi-Medium';
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.28px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #132739;
  margin-top: 1px;
  margin-bottom: 1px;
}
.item-category-modal:hover {
  background: rgba(207, 207, 207, 0.7);
  cursor: pointer;
}
.modal-categories {
  width: 76%;
  height: 224px;
  position: absolute;
  z-index: 1;
  top: 41px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 15px 10px 20px 15px;
  border-radius: 12px;
  box-shadow: 0 24px 38px 0 rgba(36, 60, 87, 0.25);
  background-color: #fff;
  &__container {
    overflow: auto;
    height: 188px;
  }
}
.carousel-categories {
  width: 96%;
  height: 28px;
  background: #ffffff;
  position: relative;
  display: inline-block;
  margin: 0 auto;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top: solid 1px #e9ebf0;
  border-right: solid 1px #e9ebf0;
  border-left: solid 1px #e9ebf0;
}
.categories-container-mini {
  width: 72%;
  line-height: 28px;
  margin: 0 auto;
  font-family: 'Avenir-Pro-Bold';
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  // border: solid 1px #e9ebf0;
  // border-bottom-right-radius: 4px;
  // border-bottom-left-radius: 4px;
  letter-spacing: -0.36px;
  text-align: center;
  color: #132839;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 5px;
  padding-right: 5px;
}

.carousel-categories .carousel-indicator {
  display: none;
}
.carousel-categories .carousel-arrow .icon.has-icons-right {
  right: 1% !important;
}
.carousel-categories .carousel-arrow .icon.has-icons-left {
  left: 1% !important;
}
.carousel-categories .carousel-arrow.is-hovered {
  opacity: 1 !important;
}
.carousel-categories .carousel-arrow .icon {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background-color: #f2f4f9;
}
.carousel-categories .carousel-arrow .icon:hover {
  border: 1px solid #f2f4f9;
}
.carousel-categories .carousel-arrow .icon {
  color: rgb(7, 7, 7);
}
.carousel-groups .carousel .carousel-indicator .indicator-item .indicator-style {
  background: #e0e0e0;
  border: none;
}
.carousel-groups .carousel .carousel-indicator .indicator-item.is-active .indicator-style,
.carousel .carousel-indicator .indicator-item .indicator-style:hover {
  background: #cbee6b;
}
</style>
